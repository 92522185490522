<template>
  <header class="header-menu-area">
    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px">
      <div class="container-fluid">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <router-link to="/" class="logo"
                  ><img
                    src="../assets/images/logo1.png"
                    alt="logo"
                    height="90"
                    width="90"
                /></router-link>
                <div class="user-btn-action">
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div class="col-lg-8">
              <div class="menu-wrapper">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        {{ $t("message.aboutSotor") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/magazines"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        {{ $t("message.magazines") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/researchers-services">
                        {{ $t("message.resServices") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us">
                        {{ $t("message.contact") }}</router-link
                      >
                    </li>
                    <li v-if="isLoggedIn">
                      <router-link to="/my-researches">
                        {{ $t("message.myRes") }}</router-link
                      >
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'ar'">
                      <span class="lang-li" @click="changeLocal('en')"
                        >English<i class="fas fa-globe"></i
                      ></span>
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'en'">
                      <span class="lang-li ar-txt" @click="changeLocal('ar')">
                        العربية
                        <i class="fas fa-globe"></i>
                      </span>
                    </li>
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
              <div
                class="off-canvas-menu custom-scrollbar-styled main-off-canvas-menu"
              >
                <div
                  class="off-canvas-menu-close main-menu-close icon-element icon-element-sm shadow-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Close menu"
                >
                  <i class="la la-times"></i>
                </div>
                <!-- end off-canvas-menu-close -->
                <div class="mt-100px mr-10px">
                  <ul class="text-center mob-menu">
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        {{ $t("message.aboutSotor") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/magazines"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        {{ $t("message.magazines") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/researchers-services">
                        {{ $t("message.resServices") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us">
                        {{ $t("message.contact") }}</router-link
                      >
                    </li>
                    <li v-if="isLoggedIn">
                      <router-link to="/my-researches">
                        {{ $t("message.myRes") }}</router-link
                      >
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'ar'">
                      <span class="lang-li" @click="changeLocal('en')"
                        >English<i class="fas fa-globe"></i
                      ></span>
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'en'">
                      <span class="lang-li ar-txt" @click="changeLocal('ar')">
                        العربية
                        <i class="fas fa-globe"></i>
                      </span>
                    </li>
                    <li>
                      <div class="col-lg-2" v-if="!isLoggedIn">
                        <router-link to="/login">
                          <section class="login-container-head">
                            <button class="login-button-head">
                              <span class="visually-hidden-head">Login</span>
                              {{ $t("message.login") }}
                            </button>
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                              class="login-icon"
                              alt=""
                            />
                          </section>
                        </router-link>
                      </div>
                      <div class="col-lg-2" v-if="isLoggedIn">
                        <section class="login-container-head btn-danger">
                          <button class="login-button-head btn-danger">
                            <span class="visually-hidden-head"></span>
                            {{ $t("message.logout") }}
                          </button>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                            class="login-icon"
                            alt=""
                          />
                        </section>
                      </div>
                    </li>
                  </ul>
                  <!-- end ul -->
                </div>
              </div>
              <!-- end off-canvas-menu -->
              <div class="body-overlay"></div>
            </div>
            <div class="col-lg-2 d-none d-md-block" v-if="!isLoggedIn">
              <router-link to="/login">
                <section class="login-container-head">
                  <button class="login-button-head">
                    <span class="visually-hidden-head">Login</span>
                    {{ $t("message.login") }}
                  </button>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                    class="login-icon"
                    alt=""
                  />
                </section>
              </router-link>
            </div>
            <div class="col-lg-2 d-none d-md-block" v-if="isLoggedIn">
              <section class="login-container-head btn-danger">
                <button class="login-button-head btn-danger">
                  <span class="visually-hidden-head"></span>
                  {{ $t("message.logout") }}
                </button>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                  class="login-icon"
                  alt=""
                />
              </section>
            </div>
            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
</template>
<script setup>
import { inject, getCurrentInstance } from "vue";
const isLoggedIn = inject("isLoggedIn");
const current = getCurrentInstance().proxy;
const changeLocal = (local) => {
  localStorage.Lang = local;
  current.$root.$i18n.locale = localStorage.Lang;
  const dir = local === "ar" ? "rtl" : "ltr";
  const bodyClass = local === "ar" ? "ar" : "en";
  localStorage.dir = dir;
  document.body.setAttribute("dir", dir);
  document.documentElement.setAttribute("dir", dir);

  document.body.classList.remove(local === "ar" ? "en" : "ar");
  document.body.classList.add(bodyClass);
};
</script>
<style>
.btn-danger {
  color: #fff;
  background-color: #bd2130 !important;
  border-color: #b21f2d !important;
}
</style>
